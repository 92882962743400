import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { PanZoom } from 'react-easy-panzoom';
import { toast } from 'react-toastify';
import axios from 'axios';

import config from '../../config';
import templates from '../../templates';
import AppContext from '../../context/AppContext';
import { importData } from '../../utils';
import linkExpiredImage from '../../assets/images/link-expired.png';

const ResumeViewMode = () => {
  const { id } = useParams();
  const context = useContext(AppContext);
  const { state, dispatch } = context;
  const { theme } = state;
  const [viewData, setViewData] = useState(null);
  const [status, setStatus] = useState('loading');
  const pageRef = useRef(null);
  const panZoomRef = useRef(null);

  useEffect(() => {
    axios
      .get(`${config.http.BASE_URL}/temporary-link/${id}`, {
        headers: { cvbuilder: process.env.REACT_APP_CV_BUILDER_HEADER_KEY },
      })
      .then((res) => {
        setViewData(res.data.link);
        importData(dispatch, { data: { ...res.data.resumeData } });
        setStatus('active');
      })
      .catch((error) => {
        if (error.response && error.response.status === 410) {
          setStatus('expired');
        } else {
          setStatus(error.response.data.message);
        }
      });
  }, [id, dispatch]);

  const handleRequestUpdate = () => {
    axios
      .post(`${config.http.BASE_URL}/temporary-link/resend/${id}`)
      .then((res) => {
        toast.info(res.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  if (status === 'loading') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        <span style={{ marginLeft: 20 }}>Checking your link...</span>
      </Box>
    );
  }

  if (status === 'expired') {
    return (
      <div
        style={{
          background: '#F5F5F5',
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            padding: '40px',
            borderRadius: '8px',
            background: '#FFF',
            height: 'fit-content',
            width: 560,
          }}
        >
          <img src={linkExpiredImage} alt="" style={{ width: 188 }} />
          <Typography style={{ fontSize: 26, fontWeight: 600, paddingTop: 24, paddingBottom: 16 }}>
            Link Expired
          </Typography>
          <Typography style={{ fontSize: 18, fontWeight: 300, paddingBottom: 24 }}>
            We apologise for the inconvenience, but you are no longer able to view the CV via this
            link. Please submit a request for access renewal below.
          </Typography>
          <Button
            onClick={handleRequestUpdate}
            style={{
              background: '#3F51B5',
              color: '#FFF',
              padding: '10px 20px',
              textTransform: 'capitalize',
              fontSize: 15,
            }}
          >
            Request access
          </Button>
        </div>
      </div>
    );
  }

  if (status === 'active' && viewData) {
    return (
      <div style={{ padding: '20px 0' }}>
        <div className="flex justify-center items-center">
          <PanZoom
            ref={panZoomRef}
            minZoom="0.4"
            maxZoom="1"
            autoCenter
            autoCenterZoomLevel={1}
            enableBoundingBox
            boundaryRatioVertical={0.8}
            boundaryRatioHorizontal={0.8}
            style={{ outline: 'none' }}
            disabled
          >
            <div id="page" ref={pageRef} className="shadow-2xl break-words mb-4">
              {templates.find((x) => theme.layout.toLowerCase() === x.key).component()}
            </div>
          </PanZoom>
        </div>
      </div>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
    >
      <Typography style={{ color: '#FF5A5F', fontWeight: 600, fontSize: 28 }}>{status}</Typography>
      <Typography variant="body1" style={{ marginTop: 10 }}>
        Please verify the link and try again.
      </Typography>
    </Box>
  );
};

export default ResumeViewMode;
