import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Button,  DialogActions } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import styles from './TempLinkPopup.module.css';

const Calendar = ({ open, setOpen, selectedDate, setSelectedDate }) => {
  const [tempDate,setTempDate] = useState(selectedDate)
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 1);

  const handleDateChange = (date) => {
    setTempDate(date)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () =>{
    setSelectedDate(tempDate);
    setOpen(false);
  }

  if (!open) return null;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ background: "#FFFF", zIndex: 99, paddingBottom: '12px' }}>
        <DatePicker
          open={true}
          variant="static"
          openTo="date"
          value={selectedDate}
          onChange={handleDateChange}
          disableToolbar
          showTodayButton
          format="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
        />
        <DialogActions
          classes={{
            root: styles.popupControls2,
          }}
        >
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Set till {tempDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}
          </Button>
        </DialogActions>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Calendar;
