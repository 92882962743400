import config from "../config";

export const GetTemporaryLinks = (username) => {
  const BaseURL = `${config.http.BASE_URL}/temporary-links?creator=${username}`;
  return fetch(BaseURL, {
    method: 'GET', headers: {
      'Content-Type': 'application/json',
      cvbuilder: `${process.env.REACT_APP_CV_BUILDER_HEADER_KEY}`,
    }
  })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};

export const SendTemporaryLink = (email, creatorEmail, resumeId, untilDate) => {
  const BaseURL = `${config.http.BASE_URL}/temporary-link`;
  return fetch(BaseURL, {
    method: 'POST', headers: {
      'Content-Type': 'application/json',
      cvbuilder: `${process.env.REACT_APP_CV_BUILDER_HEADER_KEY}`,
    },
    body: JSON.stringify({
      "untilDate": untilDate,
      "email": email,
      "resumeId": resumeId,
      "creator": creatorEmail
    }),
  })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};

export const extendDateTemporaryLink = (id, newUntilDate) => {
  const BaseURL = `${config.http.BASE_URL}/temporary-link/extend`;
  return fetch(BaseURL, {
    method: 'PUT', headers: {
      'Content-Type': 'application/json',
      cvbuilder: `${process.env.REACT_APP_CV_BUILDER_HEADER_KEY}`,
    },
    body: JSON.stringify({
      "untilDate": newUntilDate,
      "id": id
    }),
  })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};