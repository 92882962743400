import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Popover } from '@material-ui/core';
import { ReactComponent as LinkIcon } from '../../assets/images/menu-icons/link.svg'
import { ReactComponent as AdminIcon } from '../../assets/images/menu-icons/clock-spin.svg'
import { PdfItems } from './list-parts/PdfItems';
import { GoogleDocItems } from './list-parts/GoogleDocItems';

export const BurgerList = ({ setOpenLinkModal, setOpenAdmin, data, spinner, anchorEl, handleClose, sendData, templates }) => {
  const open = Boolean(anchorEl);

  const handleLinkClick = () => {
    setOpenLinkModal(true);
    handleClose();
  }

  const handleAdminClick = () => {
    setOpenAdmin(true)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{ bgcolor: 'background.paper' }}
    >
      <List component="nav">
        <PdfItems spinner={spinner} handleClose={handleClose} />
        <GoogleDocItems templates={templates} sendData={sendData} handleClose={handleClose} />
        <ListItem button style={{ gap: '8px', padding: '4px 10px' }} onClick={handleAdminClick}>
          <ListItemIcon style={{ minWidth: 0 }}>
            <AdminIcon />
          </ListItemIcon>
          <ListItemText primary="View history" />
        </ListItem>
        <ListItem button style={{ gap: '8px', padding: '4px 10px' }} onClick={handleLinkClick}>
          <ListItemIcon style={{ minWidth: 0 }}>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="Temporary link" />
        </ListItem>
      </List>
    </Popover>
  )
}
