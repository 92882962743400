import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './Admin.module.css';
import { ReactComponent as ProlongIcon } from '../../assets/images/icons/prolong.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/icons/copy.svg';
import { ReactComponent as SortIcon } from '../../assets/images/icons/sort.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { useHistory } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import { GetTemporaryLinks } from '../../services/GetTemporaryLinks';
import { toast } from 'react-toastify';
import ProlongCalendar from './ProlongCalendar';

const AdminPanel = ({ open, setOpen }) => {
  const history = useHistory();

  const searchParams = window.location.pathname;
  const selectedLinkId = searchParams.replace('/admin-panel&id=', '') || null;

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [calendarOpen, setCalendarOpen] = useState(false);

  const [links, setLinks] = useState([]);

  const context = useContext(AppContext);
  const { state } = context;
  const { ownUserInfo } = state;

  useEffect(() => {
    if (searchParams.includes('admin-panel')) {
      setOpen(true);
    }
  }, [setOpen, searchParams]);

  useEffect(() => {
    if (open === false) return;
    if (calendarOpen === true) return;
    (async () => {
      const res = await GetTemporaryLinks(ownUserInfo.username);
      setLinks(res);
    })();
  }, [open, calendarOpen, ownUserInfo.username]);

  const onClose = () => {
    if (searchParams.includes('admin-panel')) {
      history.push('/');
    }
    setOpen(false);
  };

  const handleCopy = (id) => {
    window.navigator.clipboard.writeText(
      `${process.env.REACT_APP_REDIRECT_URL}resume-view-mode/${id}`,
    );
    toast.info('Link has been copied');
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedLinks = [...links].sort((a, b) => {
      if (key === 'status') {
        const isExpiredA = new Date() < new Date(a.untilDate);
        const isExpiredB = new Date() < new Date(b.untilDate);

        if (isExpiredA === isExpiredB) return 0;
        if (sortConfig.direction === 'asc') {
          return isExpiredA ? 1 : -1;
        } else {
          return isExpiredA ? -1 : 1;
        }
      } else if (key === 'date') {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        if (dateA < dateB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      }
      return 0;
    });

    setLinks(sortedLinks);
  };

  return (
    <Dialog
      style={{ overflow: 'visible', padding: 24 }}
      open={open} // change to open pls
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        className="text-sm"
        classes={{
          root: styles.popupTitle,
        }}
        id="alert-dialog-slide-title"
      >
        Temporary Links
        <CloseIcon
          style={{ position: 'absolute', top: 0, right: 15, cursor: 'pointer' }}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible', padding: 0 }}>
        <div style={{ width: '100%', borderRadius: '4px', border: '1px solid #EDF2F7' }}>
          <div style={{ display: 'flex', padding: 12, background: '#EDF2F7' }}>
            <div style={{ flexBasis: '25%' }}>Shared with</div>
            <div
              onClick={() => handleSort('date')}
              style={{
                cursor: 'pointer',
                flexBasis: '20%',
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <div>Date, time</div> <SortIcon />
            </div>
            <div
              onClick={() => handleSort('status')}
              style={{
                cursor: 'pointer',
                flexBasis: '15%',
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <div>Status</div> <SortIcon />
            </div>
            <div style={{ flexBasis: '15%' }}>Expiry</div>
            <div style={{ flexBasis: '20%' }}>Shared by</div>
            <div style={{ flexBasis: '5%', textAlign: 'center' }}>Copy</div>
          </div>

          {links.map((link, index) => {
            const isExpired = new Date() < new Date(link.untilDate);

            return (
              <div
                key={link.id}
                style={{
                  display: 'flex',
                  padding: '14px 12px',
                  alignItems: 'center',
                  background: index % 2 === 0 ? '#FFFFFF' : '#F7FAFC',
                  border: selectedLinkId === link.id ? '1px solid #0B377F' : '',
                }}
              >
                <div style={{ flexBasis: '25%' }}>{link.email}</div>
                <div style={{ flexBasis: '20%' }}>
                  {' '}
                  {new Date(link.createdAt).toLocaleDateString('en-US', {
                    month: 'long',
                    day: '2-digit',
                  })}
                  ,{' '}
                  {new Date(link.createdAt).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </div>
                <div style={{ flexBasis: '15%' }}>
                  {isExpired ? (
                    <div
                      style={{
                        padding: '4px 8px',
                        background: '#EBFAF1',
                        borderRadius: 4,
                        width: 'fit-content',
                      }}
                    >
                      Active
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: '4px 8px',
                        background: '#F4F4F4',
                        borderRadius: 4,
                        width: 'fit-content',
                      }}
                    >
                      Expired
                    </div>
                  )}
                </div>
                <div
                  style={{
                    flexBasis: '15%',
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  {link.untilDate}
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setCalendarOpen((value) => (value ? false : link.id));
                    }}
                    style={{ cursor: 'pointer', borderRadius: '50%', padding: '1px 2px' }}
                    className={`${calendarOpen === link.id ? 'bg-gray-300' : ''} hover:bg-gray-300`}
                  >
                    <ProlongIcon />
                  </div>
                  {calendarOpen === link.id && (
                    <div
                      style={{ position: 'absolute', top: '-100px', right: '-250px', zIndex: 9999 }}
                    >
                      <ProlongCalendar
                        open={calendarOpen}
                        setOpen={setCalendarOpen}
                        untilDate={link.untilDate}
                      />
                    </div>
                  )}
                </div>
                <div style={{ flexBasis: '20%' }}>{link.creator}</div>
                <div
                  style={{
                    flexBasis: '5%',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <CopyIcon onClick={() => handleCopy(link.id)} />
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AdminPanel;
